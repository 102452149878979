import React, { useEffect } from 'react';

import './index.scss';
import { useNavigate } from 'react-router-dom';
import { Navbar } from '../../components/Navbar';
import { Footer } from '../../components/Footer';


export const NotFoundPage = () => {

    // display top of the page after navigation
    useEffect(() => {
        window.scrollTo({ top:0, left:0, behavior:'instant' });
    }, []);

    const navigate = useNavigate();

    return <>
        <Navbar />
        <div className='not-found-page text-center'>
            <h1>Oops... we can't seem to find that page</h1>
            <h2>
                The page you are looking for does not exist. It might have been moved or 
                removed
            </h2>
            <button
                className='btn btn-primary'
                onClick={() => navigate('/')}
            >
                Go to the homepage
            </button>
        </div>
        <Footer />
    </>
}