import React from 'react';

import './index.scss';


export const CTOCard = ({ 
    category, title, text, buttonText, onClick, colored, imgSrc
}) => {

    return <div className={`card feature-card ${colored && 'colored'}`}>
        <div className='card-body'>
            { imgSrc && <div className='image-container'>
                <div className='mb-4 text-center'>
                    <img src={imgSrc} alt='Tanaos' />
                </div>
            </div> }
            { category && <div className='chip mb-4'>{ category }</div>}
            <h3 className='mb-3'>{ title }</h3>
            <p className='mb-4'>{text}</p>
            <button 
                className='btn btn-primary'
                onClick={onClick}
            >
                { buttonText }
            </button>
        </div>
    </div>
};

export default CTOCard;
