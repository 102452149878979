import React from 'react';

import './index.scss';
import { LargeBlogThumbnail } from '../../../components/LargeBlogThumbnail';
import { SmallBlogThumbnail } from '../../../components/SmallBlogThumbnail'


export const ArticlesSection = ({ thumbnails }) => {

    // thumbnails 1 through 4 are larger, all others are smaller
    const largeThumbnailsTopRow = thumbnails.slice(0, 2);
    const largeThumbnailsBottomRow = thumbnails.slice(2, 4);
    const smallThumbnails = thumbnails.slice(4);

    const displayThumbnail = (size, thumbnail) => {
        if ( size === 'large' ) {
            return <LargeBlogThumbnail 
                backgroundColor={thumbnail.backgroundColor}
                imageName={thumbnail.imageName}
                category={thumbnail.category}
                date={thumbnail.date}
                title={thumbnail.title}
                articleId={thumbnail.articleId}
            />
        } else {
            return <SmallBlogThumbnail 
                backgroundColor={thumbnail.backgroundColor}
                category={thumbnail.category}
                date={thumbnail.date}
                title={thumbnail.title}
                articleId={thumbnail.articleId}
            />
        }
    };

    return <div className='articles-section'>
        <h2 className='mb-5'>See what's new at Tanaos</h2>
        {/* Large thumbnails, top row */}
        <div className='row mb-5'>
            { largeThumbnailsTopRow.map(thumbnail => (
                <div 
                    key={thumbnail.id} 
                    className={ `col-12 mt-4 mt-md-0 ${thumbnail.id === 1 ? 
                        'col-md-7' : 'col-md-5'}` }
                >
                    {displayThumbnail('large', thumbnail)}
                </div>
            ))}
        </div>
        {/* Large thumbnails, bottom row */}
        {/*
        <div className='row mt-4 mb-5'>
            { largeThumbnailsBottomRow.map(thumbnail => (
                <div 
                    key={thumbnail.id} 
                    className={ `col-12 mb-4 mb-md-0 ${thumbnail.id === 4 ? 
                        'col-md-7' : 'col-md-5'}` }
                >
                    { displayThumbnail('large', thumbnail) }
                </div>
            ))}
        </div>
        */}
        {/*
        <h3 className='mt-5'>All blog posts</h3>
        */}
        {/* Small thumbnails */}
        {/*
        <div className='row mb-5'>
            { smallThumbnails.map(thumbnail => (
                <div key={thumbnail.id} className='col-12 col-md-3 mt-4'>
                    {displayThumbnail('small', thumbnail)}
                </div>
            ))}
        </div>
        */}
    </div>
};

export default ArticlesSection;