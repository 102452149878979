import React from 'react';

import './index.scss';
import { NewsCard } from '../../components/NewsCard';


export const NewsBar = () => {
    return <div className='news-bar'>
        <h3 className='mb-5'>Latest news</h3>
        <div className='row'>
            <div className='col-12 col-md-4 mt-4 mt-md-0'>
                <NewsCard
                    type='Announcement'
                    text='Introducing llama3, our Llama3 implementation'
                    date='September 22nd, 2024'
                    link='/blog/our-custom-llama3-implementation'
                />
            </div>
            <div className='col-12 col-md-4'>
                <NewsCard 
                    type='Announcement'
                    text='Our GitHub page is now online'
                    date='September 15th, 2024'
                    link='/blog/launching-github-organization'
                />
            </div>
            <div className='col-12 col-md-4 mb-5 mb-md-0 mt-4 mt-md-0'>
                <NewsCard
                    type='Announcement'
                    text='Our Hugging Face page is now online'
                    date='September 2nd, 2024'
                    link='/blog/launching-huggingface-organization'
                />
            </div>
        </div>
    </div>
};

export default NewsBar;