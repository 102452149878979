import React from 'react';

import './index.scss';


export const Footer = () => {
    return <div className='row footer'>
        <div className='col-12 col-md-4 text-start'>
            © Copyright {new Date().getFullYear()} Tanaos
        </div>
        <div className='col-md-4 text-md-center desktop-only'>
            Tanaos
        </div>
        <div className='col-12 col-md-4 text-start text-md-end mt-4 mt-md-0'>
            <a href='/' className='me-3'>Home</a>
            <a href='/blog' className='me-3'>Blog</a>
            <a href='/company' className='me-3'>Company</a>
            <a href='/docs/getting-started/introduction'>Docs</a>
        </div>
    </div>
};

export default Footer;