import React, { useState, useEffect } from 'react';

import BlogPageLayout from '../../layouts/BlogPageLayout';
import { getAllArticleThumbnails } from '../../services';


export const BlogPage = () => {

    const [ thumbnails, setThumbnails ] = useState(null);

    // display top of the page after navigation
    useEffect(() => {
        window.scrollTo({ top:0, left:0, behavior:'instant' });
    }, []);

    // fetch articles on page load
    useEffect(() => {
        const thumbnails = getAllArticleThumbnails();
        setThumbnails(thumbnails);
    }, []);

    return thumbnails && <div>
        <BlogPageLayout 
            thumbnails={thumbnails}
        />
    </div>
};

export default BlogPage;