import React from 'react';
import { useNavigate } from 'react-router-dom';

import './index.scss';


export const SmallBlogThumbnail = ({ 
    backgroundColor, category, date, title, articleId
}) => {

    const navigate = useNavigate();

    return <div
        className='sm-blog-article-thumbnail' 
        style={{ backgroundColor }}
        onClick={() => navigate(`${articleId}`)}
    >
        <div className='text-container'>
            <h5 className='chip'>{category}</h5>
            <h3 className='mt-5'>{title}</h3>
            <h5 className='mt-5'>{date}</h5>
        </div>
    </div>
};

export default SmallBlogThumbnail;