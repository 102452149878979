import React from 'react';

import './index.scss';
import { CTOCard } from '../../../components/CTOCard';
import llama3 from '../../../assets/llama3.png';
import synthex from '../../../assets/synthex.png';
import attext from '../../../assets/attext.png';


export const ModelsSection = () => {
    return <div id='models' className='models-section'>
        <h2 className='mb-5'>Our suite of models</h2>
        <div className='main-model-container row m-0 mb-5'>
            <div className='col-12 col-md-6'>
                <h3 className='mb-4 mb-md-4'>
                    llama3 🦙🔶
                </h3>
                <p>
                    llama3 is our custom Pytorch implementation of Meta's Llama3. It was
                    designed to be readable, customizable and fully compatible with the
                    Hugging Face Llama3 8B model checkpoint. Use it to fine-tuning the
                    Hugging Face model weights, to perform inference or just to understand 
                    how Large Language Models work.
                </p>
                <div className='mt-4 mt-md-5'>
                    <button 
                        className='btn btn-primary'
                        onClick={() => window.open('https://github.com/tanaos/llama3')}
                    >
                        See on GitHub
                    </button>
                </div>
            </div>
            <div className='col-md-6 desktop-only d-flex align-items-center justify-content-center'>
                <img src={llama3} alt='Tanaos llama3' width='90%' />
            </div>
        </div>
        <div className='row'>
            <div className='col-12 col-md-6'>
                <CTOCard
                    title='Synthex 🧪📊 (under development)'
                    text='Our Python library for efficient, LLM-based synthetic data generation. Use it
                    to effortlessly fine-tune any LLM on your data and specific use-cases.'
                    buttonText='Soon on GitHub'
                    onClick={() => {}}
                    colored={true}
                    imgSrc={synthex}
                />
            </div>
            <div className='col-12 col-md-6 mt-4 mt-md-0'>
                <CTOCard 
                    title='Attext 🗃️ (under development)'
                    text='Our Python library for efficient, LLM-based attribute extraction, used to 
                    construct collections of attributes and values starting from product descriptions.'
                    buttonText='Soon on GitHub'
                    onClick={() => {}}
                    colored={true}
                    imgSrc={attext}
                />
            </div>
        </div>
    </div>
};

export default ModelsSection;