import React from 'react';
import { useNavigate } from 'react-router-dom';

import './index.scss';
import { Navbar } from '../../components/Navbar';
import { Footer } from '../../components/Footer';


export const WorkInProgressPage = () => {

    const navigate = useNavigate();

    return <>
        <Navbar />
        <div className='work-in-progress-page text-center'>
            <h1>👷👷 Work in progress ⚒️</h1>
            <h2>
                This page is under active development. Check back in a few days!
            </h2>
            <button
                className='btn btn-primary'
                onClick={() => navigate('/')}
            >
                Go to the homepage
            </button>
        </div>
        <Footer />
    </>
};

export default WorkInProgressPage;