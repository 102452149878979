import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import './index.scss';
import logo from '../../assets/logo.png';


// button that toggles the collapsible navbar component
const NavbarToggle = ({ onClick, isMenuOpen }) => {
    return isMenuOpen ? 
        <i className='bi bi-x navbar-toggle-icon' onClick={onClick}></i> :
        <i className='bi bi-list navbar-toggle-icon' onClick={onClick}></i>
};

// collapsible navbar component which contains the navbar links
const NavbarCollapse = ({ isOpen }) => {

    const navigate = useNavigate();

    return <div className={
        `navbar-collapse text-start ${isOpen && 'menu-active'}`
    }>
        <ul className='navbar-nav'>
            <li className='nav-item'>
                <span 
                    className='nav-link'
                    onClick={() => navigate('/')}
                >
                    Home
                </span>
            </li>
            <li className='nav-item'>
                <span 
                    className='nav-link'
                    onClick={() => navigate('/blog')}
                >
                    Blog
                </span>
            </li>
            <li className='nav-item'>
                <span 
                    className='nav-link'
                    onClick={() => navigate('/company')}
                >
                    Company
                </span>
            </li>
            <li className='nav-item'>
                <span 
                    className='nav-link'
                    onClick={() => navigate(
                        '/docs/getting-started/introduction'
                    )}
                >
                    Docs
                </span>
            </li>
        </ul>
    </div>
};

// the actual navbar
export const Navbar = () => {

    const [ isMenuOpen, setMenuOpen ] = useState(false);
    const navigate = useNavigate();

    const toggleMenu = () => {
        setMenuOpen(!isMenuOpen);
    };

    return <div className='row navbar m-0'>
        {/* Large devices */}
        <div className='col-8 m-0 ps-3 desktop-only text-start'>
            <img 
                className='logo'
                src={logo} 
                alt='tanaos logo' 
                onClick={() => navigate('/')} 
            />
        </div>
        <div className='col-4 text-end desktop-only'>
            <a href='/blog' className='me-5'>Blog</a>
            <a href='/company' className='me-5'>Company</a>
            <a href='/docs/getting-started/introduction'>Docs</a>
        </div>
    
        {/* Small devices */}
        <div className='col-10 text-start mobile-only'>
            <img 
                className='logo'
                src={logo} 
                alt='tanaos logo' 
                onClick={() => navigate('/')} 
            />
        </div>
        <div className='col-2 mobile-only'>
            <NavbarToggle onClick={toggleMenu} isMenuOpen={isMenuOpen} />
        </div>
        {/* display the collapsible navbar component */}
        <NavbarCollapse isOpen={isMenuOpen} />
    </div>
};


export default Navbar;