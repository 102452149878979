import React from 'react';
import { useNavigate } from 'react-router-dom';

import './index.scss';


export const CompanyHeroSection = () => {
    const navigate = useNavigate();

    return <div className='company-hero-section'>
        <div className='text-center'>
            <h1>Making AI models accessible to everyone</h1>
            <h3 className='mt-4'>
                So that anybody with a laptop can train their own
                model
            </h3>
            <button 
                className='btn btn-primary mt-5 btn-lg'
                onClick={() => navigate('/')}
            >
                See our products
            </button>
        </div>
    </div>
};

export default CompanyHeroSection;