import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';

import { BlogArticlePageLayout } from '../../layouts/BlogArticlePageLayout';
import { getBlogArticleComponentName } from '../../services/blogArticles';


export const BlogArticlePage = () => {

    const navigate = useNavigate();
    const { articleId } = useParams();
    const [ ArticleComponent, setArticleComponent ] = useState(null);

    // display top of the page after navigation
    useEffect(() => {
        window.scrollTo({ top:0, left:0, behavior:'instant' });
    }, []);

    // load requested article component; if it isn't present, throw 404 page
    useEffect(() => {
        const loadComponent = async () => {
            const filename = getBlogArticleComponentName(articleId);
            try {
                const module = await import(
                    `../../layouts/BlogArticles/${filename}`
                );
                setArticleComponent(() => module.default);
            } catch (error) {
                navigate('/404');
            }
        };
      
        loadComponent();
    }, [ articleId, navigate ])

    return ArticleComponent && <BlogArticlePageLayout 
        ArticleComponent={ArticleComponent}
    />
};

export default BlogArticlePageLayout;