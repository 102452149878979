import React from 'react';

import './index.scss';
import { useNavigate } from 'react-router-dom';


export const NewsCard = ({ type, text, date, link }) => {

    const navigate = useNavigate();

    return <div className='news-box' onClick={() => navigate(link)}>
        <div className='chip mb-4'>{ type }</div>
        <h3 className='mb-5'>{ text }</h3>
        <span>{ date }</span>
    </div>
};

export default NewsCard;