import React from 'react';

import './index.scss';
import { Navbar } from '../../components/Navbar';
import { CompanyHeroSection } from './CompanyHeroSection';
import { VisionAndMissionSection } from './VisionAndMissionSection';
import { NewsBar } from '../../components/NewsBar';
import { Footer } from '../../components/Footer';


export const CompanyPageLayout = () => {
    return <div className='company-page'>
        <Navbar />
        <CompanyHeroSection />
        <VisionAndMissionSection />
        <NewsBar />
        <Footer />
    </div>
};

export default CompanyPageLayout;