import React from 'react';

import { Navbar } from '../../components/Navbar';
import { HeroSection } from './HeroSection';
import { ModelsSection } from './ModelsSection';
import { Footer } from '../../components/Footer';


export const HomePageLayout = () => {

    return <div>
        <Navbar />
        <HeroSection />
        <ModelsSection />
        <Footer />
    </div>
};