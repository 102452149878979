import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import './index.scss';


export const LargeBlogThumbnail = ({ 
    backgroundColor, imageName, category, date, title, articleId
}) => {

    const navigate = useNavigate();

    const [imageSrc, setImageSrc] = useState(null);

    useEffect(() => {
        const importImage = async () => {
            const image = await import(`../../assets/${imageName}`);
            setImageSrc(image.default);
        };

        importImage();
    }, [ imageName ])

    return <div 
        className='lg-blog-article-thumbnail' 
        style={{ backgroundColor }}
        onClick={() => navigate(`${articleId}`)}
    >
        <div className='icon-container'>
            <img src={imageSrc} alt='icon' />
        </div>
        <div className='text-container'>
            <h5 className='mb-3'>{category} — {date}</h5>
            <h3>{title}</h3>
        </div>
    </div>
};

export default LargeBlogThumbnail;