import blogThumbnails from './blogThumbnails.json';
import blogArticleMapping from './blogArticleMapping.json'


// get all article thumbnails
export const getAllArticleThumbnails = () => {
    return blogThumbnails;
};

// get blog article component filename based on its article id
export const getBlogArticleComponentName = ( articleId ) => {
    const filename = blogArticleMapping.hasOwnProperty(articleId) ? 
        blogArticleMapping[articleId] : null;

    return filename;
};