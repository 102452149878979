import React from 'react';

import './index.scss';
import { Navbar } from '../../components/Navbar';
import { Footer } from '../../components/Footer';
import { ArticlesSection } from './ArticlesSection';


export const BlogPageLayout = ({ thumbnails }) => {
    return <div>
        <Navbar />
        <ArticlesSection 
            thumbnails={thumbnails}
        />
        <Footer />
    </div>
};

export default BlogPageLayout;