import React from 'react';

import './index.scss';


export const VisionAndMissionSection = () => {
    return <div className='vision-and-mission-section'>
        <hr />
        <div className='row mt-5 mb-5 pb-5'>
            <div className='col-12 col-md-3 mb-4 mb-md-0'>
                <h3>Our vision</h3>
            </div>
            <div className='col-12 col-md-9'>
                <p>
                    Traditional AI models are extremely resource-intensive and are therefore 
                    typically created by large tech companies. At Tanaos we believe that 
                    allowing advanced technology to be developed exclusively by a few huge 
                    organization (or countries) is the recipe for digital colonialism and the 
                    disappearence of minority cultures.
                </p>
                <p>
                    We are therefore working towards the democratization of AI. We want to
                    bring about a future in which as many people as possible, from as many
                    parts of the world as possible, can develop AI model, use them and take
                    advantage of the marvellous opportunities they offer.
                </p>
                <p>
                    We are doing this in three main ways: <b>creating resource-efficient models</b> that
                    anybody can use, train and fine-tune on their personal computers, <b>open-
                    sourcing all of them</b> and <b>making it easy to fine-tune them on any
                    on any language in the world</b>, even the least common ones.
                </p>
            </div>
        </div>
        <hr />
        <div className='row mt-5 mb-5 pb-5'>
            <div className='col-12 col-md-3 mb-4 mb-md-0'>
                <h3>Our mission</h3>
            </div>
            <div className='col-12 col-md-9'>
                <p>
                    At Tanaos we are committed to giving any person or organization who owns a laptop
                    the possibility to train their own AI model. We do this by creating compact
                    and highly efficient foundation model that can be fine-tuned on medium-
                    to low-performance machines, in just about a few minutes.
                </p>
                <p>
                    This is in stark contrast to traditional foundation AI models which, due to their
                    immense size, can only be trained and fine-tuned on extremely powerful 
                    servers running continuously for long periods of time, which only large 
                    organizations can afford.
                </p>
                <p>
                    We focus on developing tools that make it as easy as possible for anyone, even 
                    people with little to no technical ability, to take part in the AI revolution
                    which we are currently witnessing, and which we believe is not yet sufficiently
                    democratic.
                </p>
            </div>
        </div>
        <hr />
        <div className='row mt-5 mb-5'>
            <div className='col-12 col-md-3 mb-4 mb-md-0'>
                <h3>Our values</h3>
            </div>
            <div className='col-12 col-md-9'>
                <div className='row'>
                    <div className='col-12 col-md-6'>
                        <h4>
                            Accessibility
                        </h4>
                        <p>
                            We strive to make advanced AI models widely available, ensuring that even 
                            small businesses and individuals can leverage powerful AI tools without 
                            the need for extensive infrastructure.
                        </p>
                    </div>
                    <div className='col-12 col-md-6'>
                        <h4>
                            Simplicity
                        </h4>
                        <p>
                            We focus on designing AI models that are straightforward, easy to integrate, 
                            and user-friendly, allowing users to quickly deploy and benefit from AI 
                            without unnecessary complexity.
                        </p>
                    </div>
                </div>
                <div className='row mt-md-4'>
                    <div className='col-12 col-md-6'>
                        <h4>
                            Data Security
                        </h4>
                        <p>
                            We prioritiza the protection and privacy of user data by allowing users to 
                            fine-tune our models on their own laptops, without resorting to
                            any external service. We ensure that all AI solutions are designed with 
                            the highest standards of data integrity and confidentiality.
                        </p>
                    </div>
                    <div className='col-12 col-md-6'>
                        <h4>
                            Ethical AI
                        </h4>
                        <p>
                            We are committed to developing AI solutions that are transparent, fair, 
                            and designed with a strong emphasis on privacy and ethical considerations, 
                            ensuring responsible use of technology.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
};

export default VisionAndMissionSection;