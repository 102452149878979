import React from 'react';

import './index.scss';
import { Navbar } from '../../components/Navbar';
import { Footer } from '../../components/Footer';


export const BlogArticlePageLayout = ({ ArticleComponent }) => {
    return <div className='blog-article-page'>
        <Navbar />
        <div className='article-component'>
            <ArticleComponent />
        </div>
        <Footer />
    </div>
};

export default BlogArticlePageLayout;